/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useState, useEffect, Fragment } from 'react'
import { keyframes } from '@emotion/core'

const Draw = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

function ArrowSketch({ width, height }) {
  const [showAnimation, setShowAnimation] = useState(true)

  useEffect(() => {
    let animationInterval = setInterval(() => setShowAnimation((prevState) => !prevState), 2000)

    return () => {
      clearInterval(animationInterval) // this will clear Interval when the component unmounts like in willComponentUnmount
    }
  }, []) //useEffect will run only one time. If you pass a value to array, then clearInterval will run every time that value changes (useEffect re-run)

  return (
    <Fragment>
      {showAnimation && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 43.1 85.9"
          enableBackground="new 0 0 43.1 85.9"
          sx={{
            width: width,
            height: height,
            '& .body': {
              '@media (prefers-reduced-motion: no-preference)': {
                animation: Draw,
                animationTimingFunction: 'ease',
                animationDelay: '0s',
                animationDuration: '2s',
                animationFillMode: 'forwards',
                animationIterationCount: '1',
              },
              stroke: '#d9e76c',
              strokeWidth: '4',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              fill: 'none',
              strokeDasharray: '400',
              strokeDashoffset: '400',
              animation: 'undefined',
              animationTimingFunction: 'ease',
              animationDelay: '0s',
              animationDuration: '2s',
              animationFillMode: 'forwards',
              animationIterationCount: '1',
            },
            '& .tail1': {
              animationDelay: '0.3s',
            },
            '& .tail2': {
              animationDelay: '0.5s',
            },
          }}
        >
          <path className="body" d="M11.3 2.5c-5.8 5-8.7 12.7-9 20.3s2 15.1 5.3 22c6.7 14 18 25.8 31.7 33.1"></path>
          <path className="body tail1" d="M40.6 78.1C39 71.3 37.2 64.6 35.2 58"></path>
          <path className="body tail2" d="M39.8 78.5c-7.2 1.7-14.3 3.3-21.5 4.9"></path>
        </svg>
      )}
    </Fragment>
  )
}

export default ArrowSketch
