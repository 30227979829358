/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { Fragment } from 'react'
import { keyframes } from '@emotion/core'

const AnimateWave = keyframes`
  0% {
      transform: scale(1,0);
    }
  
  20% {
      transform: scale(1,1);
    }
  50% {
      transform: scale(1,0.9);
    }
  60% {
      transform: scale(1,0.9);
    }
  100% {
    transform: scale(1,1);
  }
`

const Wave = (props) => {
  const context = useThemeUI()
  const { theme } = context

  const { wave, transformFrom, landingHeroActivate = true, landingTechActivate = true, landingServicesActivate = true, waveHeight = '100%' } = props

  const wavePath = (wave) => {
    if (wave === 'hero' && landingHeroActivate) {
      const hero =
        'M0,32L30,80C60,128,120,224,180,229.3C240,235,300,149,360,112C420,75,480,85,540,122.7C600,160,660,224,720,256C780,288,840,288,900,288C960,288,1020,288,1080,256C1140,224,1200,160,1260,128C1320,96,1380,96,1410,96L1440,96L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z'

      return hero
    }
    if (wave === 'tech' && landingTechActivate) {
      const tech =
        'M0,256L48,229.3C96,203,192,149,288,112C384,75,480,53,576,53.3C672,53,768,75,864,85.3C960,96,1056,96,1152,117.3C1248,139,1344,181,1392,202.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'
      return tech
    }
    if (wave === 'services' && landingServicesActivate) {
      const services = 'M0,256L48,229.3C96,203,192,149,288,112C384,75,480,53,576,53.3C672,53,768,75,864,85.3C960,96,1056,96,1152,117.3C1248,139,1344,181,1392,202.7L1440,224L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z'
      return services
    }
    if (wave === 'wave4') {
      const wave4 =
        'M0,288L48,256C96,224,192,160,288,154.7C384,149,480,203,576,224C672,245,768,235,864,213.3C960,192,1056,160,1152,128C1248,96,1344,64,1392,48L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'
      return wave4
    }
    if (wave === 'aboutHero') {
      const aboutHero = 'M0,288L48,277.3C96,267,192,245,288,218.7C384,192,480,160,576,128C672,96,768,64,864,80C960,96,1056,160,1152,176C1248,192,1344,160,1392,144L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z'
      return aboutHero
    }
    if (wave === 'about') {
      const about =
        'M0,288L10,250.7C20,213,40,139,60,112C80,85,100,107,120,101.3C140,96,160,64,180,53.3C200,43,220,53,240,85.3C260,117,280,171,300,170.7C320,171,340,117,360,117.3C380,117,400,171,420,181.3C440,192,460,160,480,144C500,128,520,128,540,112C560,96,580,64,600,85.3C620,107,640,181,660,208C680,235,700,213,720,176C740,139,760,85,780,74.7C800,64,820,96,840,101.3C860,107,880,85,900,69.3C920,53,940,43,960,53.3C980,64,1000,96,1020,144C1040,192,1060,256,1080,282.7C1100,309,1120,299,1140,272C1160,245,1180,203,1200,186.7C1220,171,1240,181,1260,154.7C1280,128,1300,64,1320,37.3C1340,11,1360,21,1380,48C1400,75,1420,117,1430,138.7L1440,160L1440,320L1430,320C1420,320,1400,320,1380,320C1360,320,1340,320,1320,320C1300,320,1280,320,1260,320C1240,320,1220,320,1200,320C1180,320,1160,320,1140,320C1120,320,1100,320,1080,320C1060,320,1040,320,1020,320C1000,320,980,320,960,320C940,320,920,320,900,320C880,320,860,320,840,320C820,320,800,320,780,320C760,320,740,320,720,320C700,320,680,320,660,320C640,320,620,320,600,320C580,320,560,320,540,320C520,320,500,320,480,320C460,320,440,320,420,320C400,320,380,320,360,320C340,320,320,320,300,320C280,320,260,320,240,320C220,320,200,320,180,320C160,320,140,320,120,320C100,320,80,320,60,320C40,320,20,320,10,320L0,320Z'
      return about
    }
    if (wave === 'aboutApproach') {
      const aboutApproach =
        'M0,288L48,277.3C96,267,192,245,288,218.7C384,192,480,160,576,128C672,96,768,64,864,80C960,96,1056,160,1152,176C1248,192,1344,160,1392,144L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'
      return aboutApproach
    }
    if (wave === 'aboutContact') {
      const aboutContact = 'M0,128L80,112C160,96,320,64,480,53.3C640,43,800,53,960,64C1120,75,1280,85,1360,90.7L1440,96L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z'
      return aboutContact
    }
    if (wave === 'workCaseStudies') {
      const workCaseStudies = 'M0,128L48,112C96,96,192,64,288,74.7C384,85,480,139,576,154.7C672,171,768,149,864,128C960,107,1056,85,1152,74.7C1248,64,1344,64,1392,64L1440,64L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z'
      return workCaseStudies
    }
    // return '0'
  }

  const waveColor = theme.colors.wave

  return (
    <Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        style={{
          // display: 'block',
          position: 'absolute',
          zIndex: '-1',
          height: waveHeight, // 100% for mobile devices, otherwise wave shrinks
          display: 'inline-block',
          verticalAlign: 'middle',
        }}
        sx={{
          transformOrigin: transformFrom,
          '@media (prefers-reduced-motion: no-preference)': { animation: `${AnimateWave} 25000ms cubic-bezier(.67,.24,.36,1) forwards infinite alternate;` },
          animation: `undefined`,
        }}
      >
        <path fill={waveColor} fillOpacity="1" d={wavePath(wave)}></path>
      </svg>
    </Fragment>
  )
}

export default Wave
