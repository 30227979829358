/** @jsx jsx */
import { jsx, Box, Styled, Flex } from 'theme-ui'
import { Fragment, useState, useLayoutEffect } from 'react'
import { useToggle } from '../hooks'
import { animated, useSpring } from 'react-spring'
import { ButtonAnimated } from './animation'
import { Waypoint } from 'react-waypoint'
import Container from '../components/container'
import ArrowSketch from '../components/arrowSketch'
import Wave from './wave'

const AnimatedBox = animated(Box)
const AnimatedFlex = animated(Flex)

const ContactSection = ({ variant = 'primary', heading = 'Have a question?', subheading = '', linkToFirstButton = '/', buttonLabel = 'Get in touch', secondButton = false, secondLabel, variantSecondButton = 'outline', linkToSecondButton = '/', withWave = false }) => {
  const { isToggled: active, toggle: setActive } = useToggle(false)

  const [windowSize, setWindowSize] = useState([0, 0])
  const [arrowWidth, setArrowWidth] = useState()
  const [arrowHeight, setArrowHeight] = useState()

  // Detect device window size
  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useLayoutEffect(() => {
    const windowWidth = windowSize[0]

    if (windowWidth <= '320') {
      const svgWidth = '90px'
      const svgHeight = '90px'
      // eslint-disable-next-line
      return setArrowWidth(svgWidth), setArrowHeight(svgHeight)
    }
    if (windowWidth <= '640') {
      const svgWidth = '80px'
      const svgHeight = '80px'
      // eslint-disable-next-line
      return setArrowWidth(svgWidth), setArrowHeight(svgHeight)
    }
    if (windowWidth <= '832') {
      const svgWidth = '90px'
      const svgHeight = '90px'
      // eslint-disable-next-line
      return setArrowWidth(svgWidth), setArrowHeight(svgHeight)
    }
    if (windowWidth <= '1024') {
      const svgWidth = '100px'
      const svgHeight = '100px'
      // eslint-disable-next-line
      return setArrowWidth(svgWidth), setArrowHeight(svgHeight)
    }
    if (windowWidth > '1024') {
      const svgWidth = '100px'
      const svgHeight = '100px'
      // eslint-disable-next-line
      return setArrowWidth(svgWidth), setArrowHeight(svgHeight)
    }
  }, [windowSize])

  const headingFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 100 : 0,
  })
  const subtitleFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 250 : 0,
  })
  const buttonFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 350 : 0,
  })

  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: 'inherit',
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
          overflow: 'hidden',
          mt: '-2px',
        }}
      >
        {withWave && <Wave wave="aboutContact" transformFrom="top" waveHeight="auto" />}
        <Container sx={{ mt: [3, 4, 5] }}>
          <Waypoint
            bottomOffset="25%"
            onEnter={() => {
              if (!active) setActive(true)
            }}
          />
          <Flex sx={{ variant: 'layout.sectionHeroContainerDefault' }}>
            <AnimatedBox
              style={headingFade}
              sx={{
                variant: 'layout.headingContainerContactSection',
                position: 'relative',
                margin: 'auto',
              }}
            >
              <Styled.h2 sx={{ variant: 'text.heading' }}>{heading}</Styled.h2>
              <div sx={{ position: 'absolute', top: ['70px', '115px', '185px'], left: ['-45px', '-50px', '-60px'] }}>
                <ArrowSketch width={arrowWidth} height={arrowHeight} />
              </div>
            </AnimatedBox>
            <AnimatedBox
              style={subtitleFade}
              sx={{
                variant: 'layout.subHeadingContainer',
              }}
            >
              <Styled.h4 sx={{ variant: 'text.subheading' }}>{subheading}</Styled.h4>
            </AnimatedBox>
            <AnimatedFlex
              style={buttonFade}
              sx={{
                variant: 'layout.buttonContainerDefault',
              }}
            >
              <Box sx={{ my: [3, 3, 3, 3], mx: [1, 1, 3, 3] }}>
                <ButtonAnimated margin="0" variant={variant} scaleActive="1.1" label={buttonLabel} linkTo={linkToFirstButton} />
              </Box>

              {secondButton && (
                <Box sx={{ my: [3, 3, 3, 3], mx: [1, 1, 3, 3] }}>
                  <ButtonAnimated margin="0" variant={variantSecondButton} scaleActive="1.1" label={secondLabel} linkTo={linkToSecondButton} />
                </Box>
              )}
            </AnimatedFlex>
          </Flex>
        </Container>
      </Box>
    </Fragment>
  )
}

export default ContactSection
